import React, { useEffect, useState } from 'react'
import './Settings.sass'
import { withRouter } from 'react-router'
import StatusMessage, {
  useStatusMessage,
} from '../../ui/StatusMessage/StatusMessage'
import Button from '../../ui/Button/Button'
import FormRender from '../../utils/newforms/render/FormRender'
import getFieldRenderObject from '../../utils/newforms/render/getFieldRenderObject'
import RouteComponent from '../../components/RouteComponent/RouteComponent'
// import { createForm } from '../../../utils/newforms/createForm'
import isFormValid from '../../utils/newforms/validation/isFormValid'
import { isNull, omit } from 'lodash'
import getFormValues from '../../utils/newforms/getFormValues'
import { updateDoc } from '../../utils/db/updateDoc'
// import addDoc from '../../../utils/db/addDoc'
// import getDoc from '../../../utils/db/getDoc'
import Spinner from '../../ui/Spinner/Spinner'
import getDoc from '../../utils/db/getDoc'
import { createForm } from '../../utils/newforms/createForm'
import Icon from '../../ui/Icon/Icon'
import FieldRender from '../../utils/newforms/render/FieldRender'
import fileToBase64 from '../../utils/files/fileToBase64'
import ReactPlayer from 'react-player'
import PhotoManager from '../PhotoManager/PhotoManager'
import syncForm from '../../utils/newforms/changed/syncForm'
import uploadPhotos from './functions/uploadPhotos'
import uploadVideo from './functions/uploadVideo'

function Settings({ ...router }) {
  const [form, setForm] = useState(null)

  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const [isLoading, setIsLoading] = useState(false)

  const onFileAdded = (newForm, fieldId) => {
    fileToBase64(newForm[fieldId].value.file).then((converted) => {
      newForm[fieldId].value.fileUrl = converted
      setForm(newForm)
    })
  }

  useEffect(() => {
    getDoc({ path: 'settings', docId: '0tOG33b4DzEfG8BS9x7x' }).then((data) =>
      setForm(
        createForm({
          formPattern: new SettingsForm(),
          formData: data,
        })
      )
    )
  }, [])

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните все поля формы',
        closeAfter: 5000,
      })
    }
    setIsLoading(true)
    const data = getFormValues({ form })

    const values = {
      ...data,
      updated: new Date(),
      photos: data.photos
        .filter((p) => !p.needUpload)
        .map((p) => omit(p, ['file', 'fileUrl'])),
      homeVideo: omit(data.homeVideo, [
        'file',
        'fileUrl',
        'imageUrl',
        'needUpload',
        'fieldId',
        'uploadProgress',
      ]),
    }

    updateDoc({ path: 'settings', docId: '0tOG33b4DzEfG8BS9x7x', data: values })
      .then((docId) =>
        Promise.all([
          uploadVideo({ form, setForm, docId: '0tOG33b4DzEfG8BS9x7x' }),
          uploadPhotos({ photos: data.photos, docId }),
        ]).then(() => {
          setIsLoading(false)
          setStatusMessage({
            show: true,
            type: 'success',
            message: 'Данные сохранены',
            closeAfter: 5000,
          })
          window.scrollTo({ top: 0, behavior: 'smooth' })
        })
      )

      .catch((error) => {
        console.log(
          '🚀 ~ file: Settings.jsx ~ line 79 ~ onSubmit ~ error',
          error
        )
      })
  }

  return (
    <RouteComponent title={'Настройки'}>
      {!isNull(form) ? (
        <div className="Settings-Content">
          {statusMessage.show && (
            <StatusMessage
              className="Site-StatusMessage"
              type={statusMessage.type}
              message={statusMessage.message}
            />
          )}

          <p className="Title">Главная страница</p>
          <FormRender
            sections={[
              {
                fields: [
                  'meta_title',
                  'meta_desc',
                  'meta_keywords',
                  'title',
                  'description',
                ],
              },
            ]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
          <PhotoManager
            projectId={'0tOG33b4DzEfG8BS9x7x'}
            projectPath="settings"
            formData={form ? form.photos.values : null}
            syncState={(moduleState) =>
              syncForm({
                propIdentifier: 'photos',
                form,
                setForm,
                newForm: moduleState,
              })
            }
          />
          {form.homeVideo.value.publicUrl || form.homeVideo.value.fileUrl ? (
            <ReactPlayer
              url={
                form.homeVideo.value.publicUrl || form.homeVideo.value.fileUrl
              }
              width="100%"
              height="100%"
              controls
              // light={getPoster({
              //   prop: 'isClip',
              //   photos: form.photos.values,
              // })}
            />
          ) : (
            <>
              <FieldRender
                key="homeVideo"
                field={form.homeVideo}
                form={form}
                setForm={(newForm) => onFileAdded(newForm, 'homeVideo')}
                showErrors={showErrors}
              />
            </>
          )}

          <p className="Title">Контактная информация</p>
          <div className="MainInfo">
            <FormRender
              sections={[
                {
                  fields: [
                    'phone',
                    'busyHours',
                    'email',
                    'vkLink',
                    'instLink',
                    'faceLink',
                    'tmLink',
                    'viberLink',
                    'whatLink',
                    'coordinates',
                    'policyText',
                  ],
                },
              ]}
              form={form}
              setForm={setForm}
              errors={showErrors}
            />
          </div>

          <div className="Buttons">
            <Button
              title="Сохранить"
              theme="solid"
              fill="accent"
              iconPosition="right"
              size={48}
              isLoading={isLoading}
              onClick={onSubmit}
            />
          </div>
        </div>
      ) : (
        <Spinner type="module" />
      )}
    </RouteComponent>
  )
}

class SettingsForm {
  constructor() {
    this.meta_title = {
      field: {
        fieldId: 'meta_title',
        fieldType: 'input',
        inputType: 'text',
        required: true,
        label: 'Meta title',
      },
      render: getFieldRenderObject(),
    }
    this.meta_desc = {
      field: {
        fieldId: 'meta_desc',
        fieldType: 'input',
        inputType: 'text',
        required: true,
        label: 'Meta description',
      },
      render: getFieldRenderObject(),
    }
    this.meta_keywords = {
      field: {
        fieldId: 'meta_keywords',
        fieldType: 'input',
        inputType: 'text',
        required: true,
        label: 'Meta keywords',
      },
      render: getFieldRenderObject(),
    }
    this.title = {
      field: {
        fieldId: 'title',
        fieldType: 'input',
        inputType: 'text',
        required: true,
        label: 'Заглавный текст',
      },
      render: getFieldRenderObject(),
    }
    this.description = {
      field: {
        fieldId: 'description',
        fieldType: 'input',
        inputType: 'text',
        required: true,
        label: 'Описание',
      },
      render: getFieldRenderObject(),
    }
    this.phone = {
      field: {
        fieldId: 'phone',
        fieldType: 'input',
        inputType: 'text',
        required: true,
        label: 'Контактный телефон',
      },
      render: getFieldRenderObject(),
    }
    this.busyHours = {
      field: {
        fieldId: 'busyHours',
        fieldType: 'input',
        inputType: 'text',
        required: true,
        label: 'Режим работы',
      },
      render: getFieldRenderObject(),
    }
    this.email = {
      field: {
        fieldId: 'email',
        fieldType: 'input',
        inputType: 'text',
        required: true,
        label: 'E-mail',
      },
      render: getFieldRenderObject(),
    }
    this.vkLink = {
      field: {
        fieldId: 'vkLink',
        fieldType: 'input',
        inputType: 'text',
        label: 'Ссылка на VK',
      },
      render: getFieldRenderObject(),
    }
    this.instLink = {
      field: {
        fieldId: 'instLink',
        fieldType: 'input',
        inputType: 'text',
        label: 'Ссылка на Instagram',
      },
      render: getFieldRenderObject(),
    }
    this.faceLink = {
      field: {
        fieldId: 'faceLink',
        fieldType: 'input',
        inputType: 'text',
        label: 'Ссылка на Facebook',
      },
      render: getFieldRenderObject(),
    }
    this.tmLink = {
      field: {
        fieldId: 'tmLink',
        fieldType: 'input',
        inputType: 'text',
        label: 'Ссылка на Telegram',
      },
      render: getFieldRenderObject(),
    }
    this.viberLink = {
      field: {
        fieldId: 'viberLink',
        fieldType: 'input',
        inputType: 'text',
        label: 'Ссылка на Viber',
      },
      render: getFieldRenderObject(),
    }
    this.whatLink = {
      field: {
        fieldId: 'whatLink',
        fieldType: 'input',
        inputType: 'text',
        label: 'Ссылка на Whatsapp',
      },
      render: getFieldRenderObject(),
    }
    this.coordinates = {
      field: {
        fieldId: 'coordinates',
        fieldType: 'input',
        inputType: 'text',
        required: true,
        label: 'Координаты метки офиса',
      },
      render: getFieldRenderObject(),
    }
    this.policyText = {
      field: {
        fieldId: 'policyText',
        fieldType: 'texteditor',
        required: true,
        label: 'Правовой текст',
      },
      render: getFieldRenderObject(),
    }
    this.photos = {
      field: { fieldId: 'photos', value: [] },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.homeVideo = {
      field: {
        fieldId: 'homeVideo',
        fieldType: 'inputFile',
        inputType: 'file',
        label: 'Видео файл',
        icon: 'ellipsis-v',
      },
      render: getFieldRenderObject(),
    }
  }
}

export default withRouter(Settings)
